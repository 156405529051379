import {lazy} from 'react';

import { Config } from "@/config";

import rolesEnum from '@/constant/rolesEnum';

import { AdditionalProbeEdit } from '@pages/Admin/AdditionalProbe/AdditionalProbeEdit';
import { UsersRedirect } from '@pages/Admin/CollegesAndStudents/UsersRedirect';
//import EditClass from 'src/components/pages/Admin/NewStudents/Classes/components/EditClass';
import HomeWorkPageContainer from '@pages/HomeWork/HomeWorkPage.container';

const Departments = lazy(()=>import('@pages/Admin/Departments'))
const Teams = lazy(()=>import('@pages/Admin/Teams'))

const CollegesPage = lazy(()=>import('@pages/Admin/CollegesAndStudents/CollegesPageDesignChoice'))
const StudentsPage = lazy(()=>import('@pages/Admin/CollegesAndStudents/StudentsPageDesignChoice'))
const Parents = lazy(()=>import('@pages/Admin/Parents'))

const WrapperNewsViewing = lazy(()=>import('@pages/NewsViewing'))
const News = lazy(()=>import('@pages/Admin/News'))
const PreviewLesson = lazy(()=>import('@pages/Admin/PreviewLesson'))
const GroupWrap = lazy(()=>import('@pages/Admin/Reports/Group/GroupWrap'))
const Overview = lazy(()=>import('@pages/Admin/Reports/Group/Overview'))
const PlatformSettings = lazy(()=>import('@pages/Admin/PlatformSettings/PlatformSettings'))
const ThemeEditor = lazy(()=>import('@pages/Admin/ThemeEditor/Theme'))
const TaskBank = lazy(()=>import('@pages/Admin/TaskBank'))
const Personal = lazy(()=>import('@pages/Personal'))
const RatingPage = lazy(()=>import('@pages/Rating'))
const CoursesPage = lazy(()=>import('@pages/Courses'))
const LessonPageDesignChoice = lazy(()=>import('@pages/Lesson/designChoise'))
const Work = lazy(()=>import('@pages/HomeWork/Work'))
const DzStudents = lazy(()=>import('@pages/DzStudents'))
const CourseEditor = lazy(()=>import('@pages/Admin/CourseEditor'))
const Colloquium = lazy(()=>import('@pages/Colloquium'))
const Direction = lazy(()=>import('@pages/Admin/DirectionEditor'))
const Reports = lazy(()=>import('@pages/Admin/Reports'))
const LessonCheck = lazy(()=>import('@pages/Lesson/Check'))
const HistoryPayment = lazy(()=>import('@pages/HistoryPayment'))
const ControlWorkEditor = lazy(()=>import('@pages/Admin/ThemeEditor/ControlWork'))
const ColloquiumEditor = lazy(()=>import('@pages/Admin/ThemeEditor/Colloquium'))
const Requests = lazy(()=>import('@pages/Admin/Requests'))
const NewStudents = lazy(()=>import('@pages/Admin/NewStudents'))
const TaskBankAdd = lazy(()=>import('@pages/Admin/TaskBankAdd'))
const Salary = lazy(()=>import('@pages/Admin/Salary'))
const UniqueDz = lazy(()=>import('@pages/UniqueDz'))
const Checking = lazy(()=>import('@pages/DzStudents/Checking'))
const AdditionalProbe = lazy(()=>import('@pages/Admin/AdditionalProbe/AdditionalProbe'))
const AdditionalProbePage = lazy(()=>import('@pages/AdditionalProbe'))
const Control = lazy(()=>import('@pages/Control'))
const CheckingProbe = lazy(()=>import('@pages/DzStudents/CheckingProbe'))
const CoursesCheck = lazy(()=>import('@pages/Courses/check'))
const CoursePlanCheck = lazy(()=>import('@pages/CoursePlan/check'))
const CourseCheck = lazy(()=>import('@pages/Course/check'))
const ProbeCheck = lazy(()=>import('@pages/Probe/check'))
const ControlCheck = lazy(()=>import('@pages/Control/check'))
const ColloquiumCheck = lazy(()=>import('@pages/Colloquium/check'))
const CheckingControl = lazy(()=>import('@pages/DzStudents/CheckingControl'))
const LearningDynamic = lazy(()=>import('@pages/LearningDynamic'))
const CoursePageDesignChoice = lazy(()=>import('@pages/Course/designChoice'))
const ProbesPage = lazy(()=>import('@pages/Course/probes'))
const CoursePlanDesignChoice = lazy(()=>import('@pages/CoursePlan/designChoice'))
const SelfEmploymentChecks = lazy(()=>import('@pages/Admin/Reports/SelfEmploymentChecks'))
const BannerManagement = lazy(()=>import('@pages/Admin/BannerManagement'))
const BannerManagementEdit = lazy(()=>import('@pages/Admin/BannerManagement/Edit'))
const Wages = lazy(()=>import('@pages/Admin/Reports/Wages'))
const FinancialCountdown = lazy(()=>import('@pages/Admin/Reports/FinancialCountdown'))
const ContinuingEducation = lazy(()=>import('@pages/Admin/Reports/ContinuingEducation'))
const PromoCode = lazy(()=>import('@pages/Admin/Reports/PromoCode'))
const LearningDynamics = lazy(()=>import('@pages/Admin/Reports/LearningDynamics'))
const ComfortWorking = lazy(()=>import('@pages/Admin/Reports/ComfortWorking'))
const Communication = lazy(()=>import('@pages/Admin/Communication'))
const DzAnalytics = lazy(()=>import('@pages/Admin/Reports/DzAnalytics'))
const PlatformVisits = lazy(()=>import('@pages/Admin/Reports/PlatformVisits'))
const Plums = lazy(()=>import('@pages/Admin/Reports/Plums'))
const SeminarAnalytics = lazy(()=>import('@pages/Admin/Reports/SeminarAnalytics'))
const ForParents = lazy(()=>import('@pages/Admin/Reports/ForParents'))
const ComfortColleagues = lazy(()=>import('@pages/Admin/Reports/ComfortColleagues'))
const InDevelopment = lazy(()=>import('@pages/InDevelopment'))
const Group = lazy(()=>import('@pages/Admin/Reports/Group'))
const PrivateOwners = lazy(()=>import('@pages/Admin/Reports/PrivateOwners'))
const LectureAnalytics = lazy(()=>import('@pages/Admin/Reports/LectureAnalytics'))
const VideoLesson = lazy(()=>import('@pages/VideoLesson'))
const ForSeminars = lazy(()=>import('@pages/Admin/ForSeminars'))
const LearningBase = lazy(()=>import('@pages/Admin/LearningBase'))
const InputsNewPage = lazy(()=>import('@pages/Test/InputsNewPage/InputsNewPage'))
const InDevelopmentChildren = lazy(()=>import('@pages/InDevelopmentChildren'))
const SwaggerUIComponent = lazy(()=>import('@pages/Swagger'))
const Journal = lazy(()=>import('@pages/Journal'))

const Test = lazy(()=>import('@pages/Test'))
const Check = lazy(()=>import('@pages/Admin/Check'))
const ProbeEditor = lazy(()=>import('@pages/Admin/ThemeEditor/Probe'))
const Probe = lazy(()=>import('@pages/Probe'))
const PaymentsReport = lazy(()=>import('@pages/Admin/Reports/Payments'))
const MainPage = lazy(()=>import('@pages/Main'))
const SopPage = lazy(()=>import('@pages/SOP'))
const Payment = lazy(()=>import('@pages/Payment'))
const CASPersonalEdit = lazy(()=>import('@pages/Admin/CollegesAndStudents/CASPersonalEdit'))
const Sop = lazy(()=>import('@pages/Admin/Reports/Sop'))
const Sales = lazy(()=>import('@pages/Admin/Reports/Sales'))
const Payments = lazy(()=>import('@pages/Admin/Payments'))
const Library = lazy(()=>import('@pages/Library'))
const EditClass = lazy(()=>import('src/components/pages/Admin/NewStudents/Classes/components/EditClass'))

const Profile = lazy(()=>import('@pages/Profile'))
const PlatformMedia = lazy(()=>import('@pages/PlatformMedia'))
const Interrogation = lazy(()=>import('@pages/Admin/Interrogation'))

const restrictAccessStudents = true;

export const routes = [
	{
		path: '/',
		pathToLabel: '/',
		exact: true,
		label: Config.PROJECT_NAME,
		components: MainPage,
	},
	{
		path: '/personal',
		pathToLabel: '/personal',
		exact: true,
		label: 'Редактирование профиля',
		components: Personal,
	},
	{
		path: '/sop-:id',
		pathToLabel: '/sop',
		exact: true,
		label: 'Система оценки преподавателей',
		components: SopPage,
	},

	{
		path: '/rating',
		pathToLabel: '/rating',
		exact: true,
		label: 'Мой рейтинг',
		components: RatingPage,
	},

	{
		path: '/learning-base',
		pathToLabel: '/learning-base',
		exact: true,
		label: 'База знаний',
		components: LearningBase,
	},

	{
		path: '/courses',
		pathToLabel: '/courses',
		exact: true,
		label: 'Курсы',
		components: CoursesPage,
	},

	{
		path: '/courses/all',
		pathToLabel: '/all',
		exact: true,
		label: 'Все курсы',
		components: CoursesPage,
	},

	{
		path: '/courses/my',
		pathToLabel: '/my',
		exact: true,
		label: 'Мои курсы',
		components: CoursesPage,
	},
	{
		path: '/courses/my/:id',
		pathToLabel: '/course',
		exact: true,
		components: CoursePageDesignChoice,
	},
	{
		path: '/courses/my/:id/no-course/probes',
		pathToLabel: '/probes',
		exact: true,
		label: 'Доп.Пробники',
		components: ProbesPage,
	},
	{
		path: '/courses/my/:id/no-course/probes-:id',
		exact: true,
		components: AdditionalProbePage,
	},

	{
		path: '/courses/my/:id/:course',
		exact: true,
		label: 'План курса',
		components: CoursePlanDesignChoice,
	},
	{
		path: '/courses/my/:id/:course/topic-:theme',
		exact: true,
		components: LessonPageDesignChoice,
	},
	{
		path: '/courses/my/:id/:course/colloquium-:theme',
		pathToLabel: '/colloquium',
		exact: true,
		label: 'Контроль знаний',
		components: Colloquium,
	},
	{
		path: '/home-work/control-:theme',
		exact: true,
		label: 'Контроль знаний',
		components: Control,
	},

	{
		path: '/home-work/probe-:theme',
		pathToLabel: '/probe',
		exact: true,
		label: 'Контроль знаний',
		components: Probe,
	},

	{
		path: '/home-work',
		pathToLabel: '/home-work',
		exact: true,
		label: 'Мои задания',
		components: HomeWorkPageContainer,
	},
	{
		path: '/home-work/learning-dynamic',
		pathToLabel: '/learning-dynamic',
		exact: true,
		label: 'Динамика обучения',
		components: restrictAccessStudents ? InDevelopment : LearningDynamic,
	},
	{
		path: '/home-work/work-:id',
		exact: true,
		label: 'Домашняя работа',
		components: Work,
	},

	{
		path: '/video-lesson',
		pathToLabel: '/video-lesson',
		exact: true,
		label: 'Запись занятий',
		components: VideoLesson,
	},

	{
		path: '/payment',
		pathToLabel: '/payment',
		exact: true,
		label: 'Оплата и скидки',
		components: Payment,
	},

	{
		path: '/payment/history',
		pathToLabel: '/history',
		exact: true,
		label: 'История списаний и зачислений',
		components: HistoryPayment,
	},

	{
		path: '/shop',
		pathToLabel: '/shop',
		exact: true,
		label: `Магазин ${Config.PROJECT_NAME}`,
		components: restrictAccessStudents ? InDevelopment : InDevelopment,
	},

	{
		path: '/games',
		pathToLabel: '/games',
		exact: true,
		label: 'Пятиминутки и игры',
		components: restrictAccessStudents ? InDevelopment : InDevelopment,
	},

	{
		path: '/courses-check',
		pathToLabel: '/courses-check',
		exact: true,
		label: 'Доступные курсы',
		components: CoursesCheck,
		forRole: [
			rolesEnum.personalManager,
			rolesEnum.hrManager,
			rolesEnum.seminarian,
			rolesEnum.seniorSeminarian,
		],
	},

	{
		path: '/courses-check/:id',
		exact: true,
		components: CourseCheck,
		forRole: [
			rolesEnum.personalManager,
			rolesEnum.hrManager,
			rolesEnum.seminarian,
			rolesEnum.seniorSeminarian,
		],
	},

	{
		path: '/courses-check/:direction_id/:id',
		exact: true,
		components: CoursePlanCheck,
		forRole: [
			rolesEnum.personalManager,
			rolesEnum.hrManager,
			rolesEnum.seminarian,
			rolesEnum.seniorSeminarian,
		],
	},
	{
		path: '/courses-check/:direction_id/:course_id/topic-:id',
		exact: true,
		components: LessonCheck,
		forRole: [
			rolesEnum.personalManager,
			rolesEnum.hrManager,
			rolesEnum.seminarian,
			rolesEnum.seniorSeminarian,
		],
	},
	{
		path: '/courses-check/:direction_id/:course_id/probe-:id',
		exact: true,
		components: ProbeCheck,
		forRole: [
			rolesEnum.personalManager,
			rolesEnum.hrManager,
			rolesEnum.seminarian,
			rolesEnum.seniorSeminarian,
		],
	},
	{
		path: '/courses-check/:direction_id/:course_id/control-:id',
		exact: true,
		components: ControlCheck,
		forRole: [
			rolesEnum.personalManager,
			rolesEnum.hrManager,
			rolesEnum.seminarian,
			rolesEnum.seniorSeminarian,
		],
	},
	{
		path: '/courses-check/:direction_id/:course_id/colloquium-:id',
		exact: true,
		components: ColloquiumCheck,
		forRole: [
			rolesEnum.personalManager,
			rolesEnum.hrManager,
			rolesEnum.seminarian,
			rolesEnum.seniorSeminarian,
		],
	},

	{
		path: '/payments',
		pathToLabel: '/payments',
		exact: true,
		label: 'Оплаты',
		components: Payments,
		forRole: [
			rolesEnum.personalManager,
			rolesEnum.hrManager,
		],
	},

	{
		path: '/journal',
		pathToLabel: '/journal',
		exact: true,
		label: 'Электронный журнал',
		components: Journal,
		forRole: [
			rolesEnum.personalManager,
			rolesEnum.hrManager,
			rolesEnum.seminarian,
			rolesEnum.seniorSeminarian,
			rolesEnum.children,
		],
	},

	{
		path: '/students-and-group',
		pathToLabel: '/students-and-group',
		exact: true,
		label: 'Новые ученики',
		components: () => <NewStudents allowPersonalManager={true}/>,
		forRole: Config.EDITING_GROUPS_FOR_PERSONAL_MANAGER ? [rolesEnum.personalManager] : [],
	},

	{
		path: '/journal-hr',
		pathToLabel: '/journal-hr',
		exact: true,
		label: 'Журнал HR',
		components: InDevelopment,
		forRole: [
			rolesEnum.hrManager,
		],
	},

	{
		path: '/direction-editor',
		pathToLabel: '/direction-editor',
		exact: true,
		label: 'Редактирование курса',
		components: CourseEditor,
		forRole: ['hr-manager', 'senior-seminarian'],
		noMobile: true,
	},
	{
		path: '/direction-editor/direction-:id&:filterId',
		exact: true,
		components: Direction,
		forRole: ['hr-manager', 'senior-seminarian'],
		noMobile: true,
	},
	{
		path: '/direction-editor/theme-:topicId',
		exact: true,
		components: ThemeEditor,
		forRole: ['hr-manager', 'senior-seminarian'],
		noMobile: true,
	},

	{
		path: '/direction-editor/control-:id',
		exact: true,
		components: ControlWorkEditor,
		forRole: ['hr-manager', 'senior-seminarian'],
		noMobile: true,
	},
	{
		path: '/direction-editor/probe-:id',
		exact: true,
		components: ProbeEditor,
		forRole: ['hr-manager', 'senior-seminarian'],
		noMobile: true,
	},
	{
		path: '/direction-editor/colloquium-:id',
		exact: true,
		components: ColloquiumEditor,
		forRole: ['hr-manager', 'senior-seminarian'],
		noMobile: true,
	},

	{
		path: '/admin/banner-management',
		pathToLabel: '/banner-management',
		exact: true,
		label: 'Управление баннерами',
		components: BannerManagement,
		forRole: ['hr-manager'],
		noMobile: true,
	},
	// {
	// 	path: '/admin/banner-management/create',
	// 	exact: true,
	// 	label: 'Управление баннерами',
	// 	components: BannerManagementEdit,
	// 	forRole: ['hr-manager'],
	// 	noMobile: true,
	// },
	{
		path: '/admin/banner-management/:id',
		exact: true,
		label: 'Управление баннерами',
		components: BannerManagementEdit,
		forRole: ['hr-manager'],
		noMobile: true,
	},
	// {
	// 	path: '/admin/banner-management/create',
	// 	exact: true,
	// 	label: 'Управление баннерами',
	// 	components: BannerManagementEdit,
	// 	forRole: ['hr-manager'],
	// 	noMobile: true,
	// },
	{
		path: '/users',
		pathToLabel: '/users',
		label: 'Пользователи',
		exact: true,
		components: UsersRedirect,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
	},

	{
		path: '/users/parents',
		pathToLabel: '/parents',
		exact: true,
		label: 'Родители',
		components: Parents
	},

	{
		path: '/users/colleges',
		pathToLabel: '/colleges',
		exact: true,
		label: 'Коллеги',
		components: CollegesPage,
		forRole: ['hr-manager', 'senior-seminarian'],
	},

	{
		path: '/users/students',
		pathToLabel: '/students',
		exact: true,
		label: 'Ученики',
		components: StudentsPage,
		forRole: ['personal-manager', 'seminarian', 'senior-seminarian'],
	},
	
	{
		path: '/users/colleges/:edit-:id',
		exact: true,
		components: CASPersonalEdit,
		forRole: ['hr-manager', 'senior-seminarian'],
		props: {
			isEmployee: true,
		}
	},

	{
		path: '/users/students/:edit-:id',
		exact: true,
		components: CASPersonalEdit,
		forRole: ['personal-manager', 'seminarian', 'senior-seminarian'],
		props: {
			isEmployee: false,
		}
	},

	{
		path: '/requests',
		pathToLabel: '/requests',
		exact: true,
		label: 'Запросы',
		components: Requests,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
	},

	{
		path: '/salary',
		pathToLabel: '/salary',
		exact: true,
		label: 'Зарплата',
		components: Salary,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
			'other',
		],
	},

	{
		path: '/new-students',
		pathToLabel: '/new-students',
		exact: true,
		label: 'Новые ученики',
		components: NewStudents,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
	},

	{
		path: '/communication',
		pathToLabel: '/communication',
		exact: true,
		label: 'Общение',
		components: Communication,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
	},

	{
		path: '/reports',
		pathToLabel: '/reports',
		exact: false,
		label: 'Отчеты',
		components: Reports,
		noMobile: true,
	},

	{
		path: '/reports/group',
		pathToLabel: '/group',
		exact: true,
		label: 'По группе',
		components: Group,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},

	{
		path: '/reports/group-reports',
		pathToLabel: '/group-reports',
		exact: true,
		label: 'Группы',
		components: GroupWrap,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},

	{
		path: '/reports/group-overview',
		pathToLabel: '/group-overview',
		exact: true,
		label: 'По группе',
		components: Overview,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},

	{
		path: '/reports/private-owners',
		pathToLabel: '/private-owners',
		exact: true,
		label: 'По частникам',
		components: PrivateOwners,
		noMobile: true,
	},
	{
		path: '/reports/lecture-analytics',
		pathToLabel: '/lecture-analytics',
		exact: true,
		label: 'Аналитика лекций',
		components: LectureAnalytics,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/seminar-analytics',
		pathToLabel: '/seminar-analytics',
		exact: true,
		label: 'Аналитика семинаров',
		components: SeminarAnalytics,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/dz-analytics',
		pathToLabel: '/dz-analytics',
		exact: true,
		label: 'Аналитика дз',
		components: DzAnalytics,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/platform-visits',
		pathToLabel: '/platform-visits',
		exact: true,
		label: 'Посещения платформы',
		components: PlatformVisits,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/plums',
		pathToLabel: '/plums',
		exact: true,
		label: 'По сливам',
		components: Plums,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/for-parents',
		pathToLabel: '/for-parents',
		exact: true,
		label: 'Отчет для родителей',
		components: ForParents,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/comfort-colleagues',
		pathToLabel: '/comfort-colleagues',
		exact: true,
		label: 'Комфорт коллег',
		components: ComfortColleagues,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/sop',
		pathToLabel: '/sop',
		exact: true,
		label: 'СОП учеников',
		components: Sop,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/sales',
		pathToLabel: '/sales',
		exact: true,
		label: 'По продажам',
		components: Sales,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/self-employment-checks',
		pathToLabel: '/self-employment-checks',
		exact: true,
		label: 'По чекам самозанятых',
		components: SelfEmploymentChecks,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/wages',
		pathToLabel: '/wages',
		exact: true,
		label: 'По ЗП',
		components: Wages,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/financial-countdown',
		pathToLabel: '/financial-countdown',
		exact: true,
		label: 'Финансовый отчет',
		components: FinancialCountdown,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/continuing-education',
		pathToLabel: '/continuing-education',
		exact: true,
		label: 'Продление обучения',
		components: ContinuingEducation,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/promo-code',
		pathToLabel: '/promo-code',
		exact: true,
		label: 'По промокодам',
		components: PromoCode,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/learning-dynamics',
		pathToLabel: '/learning-dynamics',
		exact: true,
		label: 'Отчет динамики обучения',
		components: LearningDynamics,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/comfort-working',
		pathToLabel: '/comfort-working',
		exact: true,
		label: 'Комфорт работы',
		components: ComfortWorking,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/reports/payments',
		pathToLabel: '/payments',
		exact: true,
		label: 'Оплаты',
		components: PaymentsReport,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},

	{
		path: '/unique-dz',
		pathToLabel: '/unique-dz',
		exact: true,
		label: 'Сформировать уникальное ДЗ',
		components: UniqueDz,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/dz-students',
		pathToLabel: '/dz-students',
		exact: true,
		label: 'Домашнее задание учеников',
		components: DzStudents,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		// noMobile: true,
	},
	{
		path: '/dz-students/:type',
		exact: true,
		label: 'Домашняя работа учеников',
		components: DzStudents,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		// noMobile: true,
	},
	{
		path: '/dz-students/checking/:id',
		exact: true,
		label: 'Домашняя работа учеников',
		components: Checking,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		// noMobile: true,
	},

	{
		path: '/dz-students/checkingProbe/:id',
		exact: true,
		label: 'Пробники учеников',
		components: CheckingProbe,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		// noMobile: true,
	},
	{
		path: '/dz-students/checkingControl/:id',
		exact: true,
		label: 'Контрольные учеников',
		components: CheckingControl,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		// noMobile: true,
	},

	{
		path: '/dz-colleagues',
		pathToLabel: '/dz-colleagues',
		exact: true,
		label: 'Домашнее задание коллег',
		components: InDevelopment,
		// components: DZColleges,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
	},

	{
		path: '/task-bank',
		pathToLabel: '/task-bank',
		exact: true,
		label: 'Банк и архив задач',
		components: TaskBank,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
	},

	{
		path: '/task-bank/:id',
		pathToLabel: '/task-bank',
		exact: true,
		label: 'Банк и архив задач',
		components: TaskBankAdd,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
	},

	{
		path: '/additional-probe',
		pathToLabel: '/additional-probe',
		exact: true,
		label: 'Дополнительный пробник',
		components: AdditionalProbe,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},
	{
		path: '/additional-probe/:id&:directionId',
		exact: true,
		label: 'Добавить дополнительный пробник',
		components: AdditionalProbeEdit,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
		noMobile: true,
	},

	{
		path: '/advertisement',
		pathToLabel: '/advertisement',
		exact: true,
		label: 'Рассылка/Реклама',
		components: InDevelopment,
		forRole: ['personal-manager', 'hr-manager'],
	},
	{
		path: '/for-seminars',
		pathToLabel: '/for-seminars',
		exact: true,
		label: 'Для семинаров',
		components: ForSeminars,
		forRole: ['seminarian', 'senior-seminarian'],
	},
	{
		path: '/check',
		pathToLabel: '/check',
		exact: true,
		label: 'Просмотр работ',
		components: Check,
		forRole: [
			'seminarian',
			'senior-seminarian',
			'personal-manager',
			'hr-manager',
		],
	},

	{
		path: '/no-access',
		pathToLabel: '/no-access',
		exact: true,
		label: 'Нет доступа',
		components: InDevelopmentChildren,
	},

	{
		path: '/test',
		pathToLabel: '/test',
		exact: true,
		label: 'Тестовая страница',
		components: Test,
	},
	{
		path: '/test/inputs',
		pathToLabel: '/inputs',
		exact: true,
		label: 'Демонстрация полей ввода',
		components: InputsNewPage,
	},
	{
		path: '/platform-settings',
		exact: true,
		label: 'Настройки платформы',
		components: PlatformSettings,
		forRole: [
			'admin',
			'junior-admin',
		],
	},
	{
		path: '/preview-lesson',
		exact: true,
		label: 'Предпросмотр урока',
		components: PreviewLesson,
		forRole: [
			'admin',
			'junior-admin',
			'seminarian',
			'senior-seminarian'
		],
	},
	{
		path: '/swagger',
		exact: true,
		label: 'Impulse API',
		components: SwaggerUIComponent,
	},
	{	path: '/news',
		exact: true,
		label: 'Новости',
		components: News,
		forRole: [
			'seminarian',
			'senior-seminarian',
			'personal-manager',
			'hr-manager',
		],
	},
	{
		path: '/news/:id',
		exact: true,
		label: 'Новость',
		components: WrapperNewsViewing,
		forRole: [
			'seminarian',
			'senior-seminarian',
			'personal-manager',
			'hr-manager',
			'children'
		],
	},
	{
		path: '/departments',
		exact: true,
		label: 'Отделы',
		components: Departments,
		// forRole: [
		// 	'seminarian',
		// 	'senior-seminarian',
		// 	'personal-manager',
		// 	'hr-manager',
		// 	'children'
		// ],
	},
	{
		path: '/teams',
		exact: true,
		label: 'Команды',
		components: Teams,
	},
	{
		path: '/library',
		exact: true,
		label: 'Конфлюенс',
		components: Library,
		forRole: [
			'seminarian',
			'senior-seminarian',
			'personal-manager',
			'hr-manager',
			'children'
		],
	},

	{
		path: '/new-students/edit-class',
		pathToLabel: '/new-students/edit-class',
		exact: true,
		label: 'Новые классы',
		components: EditClass,
		forRole: [
			'personal-manager',
			'hr-manager',
			'seminarian',
			'senior-seminarian',
		],
	},
	{
		path: '/profile',
		exact: true,
		label: 'Мой профиль',
		components: Profile,
		forRole: [
			'children',
		],
	},
	{
		path: '/platform-media',
		exact: true,
		label: 'Материалы',
		components: PlatformMedia,
		forRole: [
			'seminarian',
			'senior-seminarian',
			'personal-manager',
			'hr-manager',
		],
	},
	{
		path: '/interrogation',
		exact: true,
		label: 'Опросы',
		components: Interrogation
	},
];
